header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;
	background-color: white;
	font-size: 18px;
	border-bottom: 2px solid $grayL;
	@media(max-width: $max-menu){
		#logo svg path, #logo svg polygon {
			fill: white !important;
		}
		#shopping-cart path {
			stroke: white;
		}
		&.open {
			position: fixed;
			height: 100vH;
			background-color: $red;
			color: white;
			#logo svg path, #logo svg polygon {
				fill: white !important;
			}
		}
	}
	#logo {
		display: block;
		padding-top: 20px;
		padding-bottom: 20px;
		padding-left: 40px;
		float: left;
		transition: all 0.2s;
		@media(max-width: $max-menu){
			padding-left: 20px;
		}
		svg {
			height: 40px;
			width: auto;
			@media(max-width: $max-sm){
				height: 40px;
			}
		}
	}
	nav {
		display: inline-block;
		font-size: 18px;
	}
	#navs-wrap {
		float: right;
		padding: 0 50px;
		line-height: $headerH;
		border-left: 2px solid $grayL;
		transition: all 0.2s;
		@media(max-width: $max-menu){
			display: none;
			width: 100%;
			padding: 20px 20px;
			border-left: 0;
			border-top: 1px solid white;
			line-height: 2em;
		}
	}
	#pages-nav {
		line-height: $headerH;
		float: right;
		margin-right: 50px;
		transition: all 0.2s;
		@media(max-width: $max-menu){
			display: none;
			position: fixed;
			bottom: 30px;
			left: 20px;
			font-size: 60px;
			font-weight: $black;
			margin: 0;
		}
		a {
			margin-left: 30px;
			@media(max-width: $max-menu){
				display: block;
				margin: 0;
			}
		}
	}
	#lang-nav {
		margin-right: 30px;
		@media(max-width: $max-menu){
			width: 100%;
			font-size: 24px;
			font-weight: $bold;
		}
		a {
			&.current-menu-item {
				color: $gray;
				opacity: 0.5;
				@media(max-width: $max-menu){
					color: rgba(255,255,255,0.2);
				}
			}
		}
	}
	#intranet-link {
		border-bottom: 3px solid $gray;
		@media(max-width: $max-menu){
			font-size: 24px;
			font-weight: 700;
			border: 0;
		}
	}
	&:not(.fixed) {
		//border-bottom: 0;
	}
	&.fixed {
		#logo {
			padding-top: 12px;
			padding-bottom: 12px;
		}
		#logo-text {
			position: relative;
			top: 12px;
			> path, #arts-venture {
				display: none;
			}
		}
		#navs-wrap, #pages-nav {
			line-height: $headerHfixed;
		}
	}
}

body.legal, body.checkout {
	@media(max-width: $max-menu){
		header:not(.open){
			#logo svg path, #logo svg polygon {
				fill: $red !important;
			}
			#shopping-cart path {
				stroke: $red;
			}
		}
	}
}


#menu-trigger {
	display: none;
	cursor: pointer;
	width: 30px;
	height: 30px;
	background-image: url('../img/menu_white.svg');
	background-size: auto 30px;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	right: 0;
	top: 0;
	height: $headerH;
	width: $headerH;
	border-left: 1px solid white;
	
	@media(max-width: $max-menu){
		display: block;
		border-left: 1px solid $grayL;
	}
}
.fixed #menu-trigger {
	position: fixed;
	height: $headerHfixed;
	width: $headerH;
}
.home #menu-trigger, .legal #menu-trigger, .checkout #menu-trigger {
	background-image: url('../img/menu_black.svg');
}
.open {
	#menu-trigger {
		background-image: url('../img/close.png');
	}
	#navs-wrap, #pages-nav {
		display: block;
	}
	#logo-text {
		g {
			//display: none;
		}
	}
}

@media(max-width: $max-menu){
	.courses header:not(.open), .course-single header:not(.open) { background-color: $greenD; }
	.aurea header:not(.open) { background-color: $yellowD; }
	.news header:not(.open)  { background-color: $beigeD; }
	.faqs header:not(.open)  { background-color: $orangeD; }
}

#header-cart, #header-cart_responsive {
	display: inline-block;
	margin-right: 30px;
	position: relative;
	span {
		width: 14px;
		height: 14px;
		line-height: 14px;
		border-radius: 50%;
		background-color: $red;
		color: white;
		font-size: 9px;
		font-weight: 500;
		text-align: center;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(50%,-100%);
	}
}
#header-cart_responsive {
	display: none;
	position: fixed;
	top: 40px;
	transform: translateY(-50%);
	right: 80px;
	transition: top 0.3s;
	.fixed & {
		top: 33px;
	}
}
header.open #header-cart_responsive {
	path {
		stroke: white;
		fill: $red !important;
	}
	span {
		background-color: white;
		color: $red;
	}
}
@media(max-width: $max-menu){
	#header-cart{
		display: none;
	}
	#header-cart_responsive {
		display: block;
	}

}