.legal {
	main {
		padding: 80px 0;
		h1, h2, h3 {
			color: #453D3D;
			margin-bottom: 40px;
		}
		h3 {
			margin: 20px 0;
		}
		p {
			margin-bottom: 40px;
		}
	}
}