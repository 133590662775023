.container-fluid {
	padding-left: 40px;
	padding-right: 40px;
	@media(max-width: $max-sm){
		padding-left: 20px;
		padding-right: 20px;
	}
	&.container-margin {
		padding-left: 120px;
		padding-right: 120px;
		@media(max-width: $max-md){
			padding-left: 60px;
			padding-right: 60px;
		}
		@media(max-width: $max-sm){
			padding-left: 30px;
			padding-right: 30px;
		}
		@media(max-width: $max-xs){
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}
.thumb {
	background-size: cover;
	background-position: center;
	width: 100%;
}
main {
	margin-top: 70px;
}

.page-intro {
	height: calc(100vH - 70px);
	display: flex;
	background-repeat: no-repeat;
	background-size: 50% auto;
	background-position: right bottom;
	@media(max-width: $max-md){
		display: block;
		height: auto;
	}
	.thumb, .content {
		width: 50%;
		height: 100%;
		@media(max-width: $max-md){
			width: 100%;
			min-height: 50vH;
			height: auto;
		}
	}
	.thumb {
		@media(max-width: $max-md){
			height: 285px;
			min-height: 0;
		}
	}
	.content {
		color: white;
		padding: 50px 45px;
		font-size: 24px;
		max-width: 620px;
		position: relative;
		@media(max-width: $max-md){
			max-width: 100%;
			min-height: 0;
			padding-bottom: 100px;
		}
		@media(max-width: $max-sm){
			padding: 40px 35px;
			padding-bottom: 100px;
			font-size: 18px;
		}
		@media(max-width: $max-xs){
			padding: 30px 25px;
			padding-bottom: 100px;
		}
		h1 {
			color: white;
			margin-bottom: 30px;
			@media(max-width: $max-sm){
				margin-bottom: 15px;
			}
		}
	}
	.arrow {
		position: absolute;
		bottom: 40px;
		left: 40px;
		width: 30px;
		cursor: pointer;
		animation: jump 2s infinite;
		@media(max-width: $max-md){
			width: 25px;
			left: auto;
			right: 25px;
			bottom: 25px;
		}
	}
}
@keyframes jump {
  0% { bottom: 60px; } 
  50% { bottom: 40px; } 
  100% { bottom: 60px; }
}
* {
	-webkit-appearance: none;
}
input, select {
	border-radius: 0;
}
.modal-backdrop.show {
    opacity: .3;
}

body[data-cursor="left"]{
	cursor: url(../img/left.svg), auto;
}
body[data-cursor="right"]{
	cursor: url(../img/right.svg), auto;
}

@media(min-width: $max-md){
	.onShow {
		opacity: 0;
		transition: all 1s 0.2s;
		position: relative;
		top: 120px;
		&.on {
			opacity: 1;
			top: 0;
		}
	}
	.onShowMargin {
		opacity: 0;
		transition: all 0.4s 0.3s;
		margin-top: 50px;
		&.on {
			opacity: 1;
			margin-top: 0;
		}
	}
}

::selection {
	color: white;
	background-color: $red;
}
::-moz-selection {
	color: white;
	background-color: $red;
}




#intranet_landing {
	background-color: $red;
	padding: 40px 35px;
	width: 50%;
	height: 100vH;
	text-align: left;
	float: left;
	display: flex;
	flex-direction: column;
	#logo {
		svg {
			height: 60px;
			width: auto;
			margin-bottom: 100px;
			path, polygon {
				fill: white !important;
			}
		}
	}
	h1 {
		color: white;
		font-size: 65px;
		line-height: 70px;
		font-weight: 900;
		flex-grow: 1;
	}
	.button {
		border: 2px solid white;
		width: 250px;
		margin-top: 50px;
	}
	@media(max-width: $max-md){
		width: 100%;
		min-height: 50vH;
		height: auto;
		#logo {
			svg {
				height: 40px;
				margin-bottom: 50px;
			}
		}
		h1 {
			font-size: 40px;
			line-height: 45px;
		}
	}
	@media(max-width: $max-xs){
		h1 {
			font-size: 25px;
			line-height: 30px;
			font-weight: 700;
		}
	}
}
#intranet_landing_img {
	width: 50%;
	height: 100vH;
	float: left;
	background-color: $red;
	background-image: url('../img/intranet_back.jpg');
	background-size: cover;
	background-position: center;
	@media(max-width: $max-md){
		width: 100%;
		height: 50vH;
	}
}