@font-face {
  font-family: 'Mark';
  src: url('../fonts/FontFont_FF.Mark.Pro.Light.otf')  format('opentype'),
	   url('../fonts/FontFont_FF.Mark.Pro.Light.woff') format('woff');
  font-weight: 300;
  font-style: normal; 
} 
@font-face {
  font-family: 'Mark';
  src: url('../fonts/FontFont_FF.Mark.Pro-3.otf')  format('opentype'),
	   url('../fonts/FontFont_FF.Mark.Pro-3.woff') format('woff');
  font-weight: normal;
  font-style: normal; 
} 
@font-face {
  font-family: 'Mark';
  src: url('../fonts/FontFont_FF.Mark.Pro.Bold.otf')  format('opentype'),
	   url('../fonts/FontFont_FF.Mark.Pro.Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal; 
} 
@font-face {
  font-family: 'Mark';
  src: url('../fonts/FontFont_FF.Mark.Pro.Black.otf')  format('opentype'),
	   url('../fonts/FontFont_FF.Mark.Pro.Black.woff') format('woff');
  font-weight: 900;
  font-style: normal; 
} 