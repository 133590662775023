.login-page {
	height: 100vH;
	background-image: url('../img/background-intranet.png');
	background-position: center;
	background-size: cover;
	#logo {
		position: absolute;
		height: 60px;
		width: auto;
		top: 45px;
		left: 45px;
		path, polygon {
			fill: white !important;
		}
		@media(max-width: $max-sm){
			height: 40px;
			left: 25px;
			top: 25px;
		}
	}
	h1 {
		font-size: 40px;
	}
	#signin-form {
		text-align: center;
		background: white;
		padding: 40px;
		width: 380px;
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		box-shadow: 0 0 7px $gray;
		max-width: calc(100% - 50px);
		@media(max-width: $max-sm){
			padding: 35px 20px;
			h1 {
				margin-bottom: 45px;
			}
		}
		input {
			width: 100%;
			border: 2px solid $grayL;
			border-radius: 0;
			line-height: 56px;
			height: 56px;
			padding: 5px 10px 5px 50px;
			background-repeat: no-repeat;
			&::placeholder {
				color: rgba(0,0,0,0.25);
			}
			&#email { 
				background-position: left 10px center; 
				background-size: 25px auto;
				background-image: url('../img/login/email.png'); 
			}
			&#password { 
				background-position: left 15px center; 
				background-size: 18px auto;
				background-image: url('../img/login/pass.png'); 
			}
			&[type='submit']{
				width: 100%;
				border: 0;
				background-color: $red;
				color: white;
				padding: 0;
				font-weight: $bold;
				cursor: pointer;
			}
		}
		.help-block {
			font-size: 12px;
			margin-top: 10px;
			color: $red;
		}
		#forgot_password {
			color: rgba(0,0,0,0.2);
			font-size: 12px;
			text-align: right;
			display: block;
		}
	}
}