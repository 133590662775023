$green: #D2E1C8;
$greenD: #A1AF97;
$orange: #F9C4AA;
$orangeD: #C5937B;
$yellow: #FEE4A6;
$yellowD: #CAB277;
$beige: #ECE1D7;
$beigeD: #BAAFA6;
$red: #FE4A68;
$gray: #453D3D;
$grayL: #f4f4f4;

$mark: 'Mark',sans-serif;

$max-lg: 1199px;
$max-lm: 1024px;
$max-md: 991px;
$max-menu: 900px;
$max-sm: 767px;
$max-xs: 575px;

$min-xl: 1800px;
$min-lg: 1200px;
$min-md: 992px;
$min-sm: 768px;
$min-xs: 576px;

$light: 300;
$regular: 400;
$bold: 700;
$black: 900;

$headerH: 80px;
$headerHfixed: 64px;
$headerHM: 80px;