#course_header {
	background-color: $green;
	display: flex;
	flex-direction: row;
	@media(max-width: $max-md){
		margin-bottom: 0;
		flex-direction: column;
	}
	.thumb, .content {
		width: 50%;
		@media(max-width: $max-md){
			width: 100%;
			min-height: 50vH;
			height: auto;

		}
	}
	.thumb {
		height: 480px;
		@media(max-width: $max-md){
			height: 285px;
			min-height: 0;
		}
	}
	.content {
		padding: 40px;
		@media(max-width: $max-md){
			max-width: 100%;
			min-height: 0;
			padding-bottom: 50px;
			padding: 40px 35px;
			padding-bottom: 100px;
			font-size: 18px;
		}
		@media(max-width: $max-xs){
			padding: 20px 25px 40px;
		}
		h4 {
			color: $greenD;
			text-transform: uppercase;
			font-size: 16px;
			font-weight: $bold;
			margin-bottom: 10px;
		}
		h1 {
			color: white;
			@media(max-width: $max-xs){
				font-size: 39px;
			}
		}
	}
}
#next-course {
	margin-bottom: 80px;
	background-color: $red;
	padding-top: 20px;
	padding-bottom: 20px;
	color: white;
	font-size: 30px;
	font-weight: $black;
	height: 80px;
	line-height: 40px;
	.button {
		float: right;
		border: 2px solid white;
		margin-top: -10px;
		width: 175px;
	}
	&.fixed {
		position: fixed;
		left: 0;
		top: 66px;
		width: 100%;
		z-index: 99;
	}
}
#responsive-buttons-course {
	display: flex;
	flex-direction: row;
	margin-bottom: 45px;
	.button {
		width: 50%;
		padding: 0 5px;
	}
	form {
		width: 50%;
		input.button {
			width: 100%;
		}
	}
}
#course-sidebar {
	h3 {
		color: $green;
		line-height: 32px;
		margin-bottom: 20px;
	}
	nav {
		overflow: auto;
		menuitem {
			float: left;
			clear: both;
			cursor: pointer;
			font-size: 18px;
			margin-bottom: 15px;
			padding-bottom: 2px;
			position: relative;
			&:after {
				content: ' ';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0;
				height: 2px;
				background: $green;
				transition: all 0.5s;
			}
			&.active:after, &:hover:after {
				width: 100%;
			}
		}
	}
	.button {
		display: block;
		width: 100%;
	}
	&.fixed {
		position: fixed;
		left: 120px;
		top: 90px;
	}
}
#course-sidebar {
	padding-right: 100px;
	.button {
		margin-top: 15px;
	}
}
#responsive-course-content {
	h2 {
		margin-bottom: 15px;
		text-align: center;
		font-size: 45px;
	}
}
#course-content {
	h2 {
		font-size: 45px;
		line-height: 45px;
		color: $green;
		margin-bottom: 20px;
		@media(max-width: $max-md){
			color: $gray;
			font-size: 24px;
			line-height: 30px;
			font-weight: $bold;
			cursor: pointer;
			margin-bottom: 0;
			padding-right: 30px;
			padding-top: 15px;
			padding-bottom: 15px;
			position: relative;
			&:after {
				content: '+';
				color: $red;
				float: right;
				font-weight: 400;
				font-size: 50px;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}
			&.open {
				&:after {
					content: '-';
				}
				+ .content {
					padding-bottom: 30px;
				}
			}
		}
	}
	.course-section > .content{
		@media(max-width: $max-md){
			display: none;
		}
	}
	.course-section {
		margin-bottom: 40px;
		padding-bottom: 40px;
		border-bottom: 1px solid $grayL;
		@media(max-width: $max-md){
			margin-bottom: 0;
			padding-bottom: 0;
			&:last-child {
				margin-bottom: 40px;
				border-bottom: 1px solid $grayL;
			}
		}
	}
	#general-info {
		img {
			margin-right: 15px;
			@media(max-width: $max-xs){
				margin-right: 0;
				margin-top: 15px;
			}
		}
		table {
			margin-top: 20px;
			@media(max-width: $max-xs){
				width: 100%;
				margin-top: 10px;
			}
		}
		td {
			padding-bottom: 15px;
			&:nth-child(2){
				padding-right: 65px;
				@media(max-width: $max-xs){
					padding-right: 0;
				}
			}
			@media(max-width: $max-xs){
				width: 100%;
				display: block;
				text-align: center;
				padding: 0;
			}
		}
		.discounted {
			position: relative;
			color: #d8d8d8;
			&:after {
				content: " ";
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: $red;
			}
		}
	}
	iframe {
		width: 100% !important;
	}
}
#what-you-get {
	.content {
		padding-left: 30px;
		@media(max-width: $max-sm){
			padding-left: 45px;
		}
	}
	h3 {
		color: $green;
		font-weight: $bold;
		font-size: 24px;
		margin-top: 40px;
		counter-increment: section;
		position: relative;
		&:before {
  			content: counter(section);
  			position: absolute;
  			font-size: 14px;
  			color: white;
  			width: 20px;
  			height: 20px;
  			line-height: 20px;
  			text-align: center;
  			background-color: $green;
  			border-radius: 50%;
  			left: -30px;
  			top: 7px;
  			@media(max-width: $max-sm){
				width: 32px;
  				height: 32px;
  				line-height: 32px;
  				font-size: 18px;
  				left: -45px;
			}
		}
		@media(max-width: $max-sm){
			font-size: 18px;
			margin-top: 20px;
		}
	}
}
#teaching-staff {
	#team {
		padding:0;
		.teacher {
			@media(max-width: $max-sm){
				.thumb {
					height: 430px;
				}
				.content {
					display: block;
					padding-top: 15px;
					margin-bottom: 30px;
				}
			}
		}
	}
}
#course-testimonials {
	padding: 40px 0 100px;
	background-color: $grayL;
	margin-top: 0;
	@media(max-width: $max-sm){
		padding: 40px 0 50px;
	}
	h2 {
		font-size: 45px;
		color: $green;
	}
	.testimonial {
		background: white;
	}
	.testimonial-wrap {
		@media(max-width: $max-sm){
			padding: 0 25px;
		}
	}
}
#course-faqs {
	padding-top: 80px;
	padding-bottom: 65px;
	@media(max-width: $max-sm){
		padding-top: 40px;
		padding-bottom: 60px;
	}
	h2 {
		font-size: 45px;
		color: #D2E1C8;
		padding-bottom: 20px;
	}
	.faq {
		padding-bottom: 15px;
		margin-bottom: 15px;
		border-bottom: 2px solid $grayL;
		&:first-of-type {
			border-top: 2px solid $grayL;
			padding-top: 15px;
		}
		h3 {
			color: $gray;
			font-size: 24px;
			font-weight: $bold;
			cursor: pointer;
			padding-right: 40px;
			position: relative;
			&:after {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				content: '+';
				color: $red;
				float: right;
				font-weight: 400;
				font-size: 50px;
			}
			&.open:after {
				content: '-';
			}
			@media(max-width: $max-sm){
				font-size: 18px;
				font-weight: 400;
			}
		}
		.content {
			display: none;
			padding-top: 40px;
			@media(max-width: $max-sm){
				padding-top: 20px;
			}
		}
	}
}

#requestInformation {
	padding-right: 0 !important;
	.modal-content {
		padding: 50px 40px;
		border-radius: 0;
		border: 0;
		@media(max-width: $max-sm){
			padding: 40px 20px 24px;
		}
		h3 {
			color: $gray;
			margin-bottom: 25px;
			@media(max-width: $max-sm){
				font-size: 30px;
			}
		}
		.newsletter-group{
			font-size: 16px;
			font-weight: $bold;
			color: rgba(69,61,61,0.2);
			@media(max-width: $max-sm){
				margin-top: 30px;
			}
		}
		small {
			display: block;
			color: rgba(69,61,61,0.2);
			font-size: 12px;
			line-height: 14px;
			margin-bottom: 35px;
			@media(max-width: $max-sm){
				margin-bottom: 5px;
			}
		}
		.button {
			width: 100%;
		}
	}
	.modal-dialog {
		//margin: 25px;
	}
}
#formSuccess {
	display: none;
}

.course-single #courses {
	padding-top: 80px;
	padding-bottom: 80px;
}

#course-lessons {
	counter-reset: lesson;
	.lesson-list {
		font-size: 18px;
		font-weight: 700;
		padding: 15px 0;
		border-top: 1px solid $grayL;
		counter-increment: lesson;
		position: relative;
		cursor: pointer;
		&:before {
			content: counter(lesson);
			color: #D8D8D8;
			margin-right: 5px;
		}
		img {
			position: absolute;
			top: 24px;
			right: 0;
			transform: rotate(90deg);
			transition: all 0.2s;
		}
		.content {
			padding: 10px 20px 0;
			font-weight: 400;
			&.collapse.show + img {
				transform: rotate(270deg);
			}
		}
	}
}

#course-blocks {
	.content {
		margin-top: 40px;
	}
	.course-block {
		margin-bottom: 20px;
		.thumb {
			padding-bottom: 120%;
			@media(max-width: $max-xs){
				margin-bottom: 15px;
			}
		}
		b {
			font-size: 24px;
			margin-bottom: 10px;
			display: inline-block;
		}
		p {
			font-size: 18px;
			@media(max-width: $max-xs){
				text-align: center;
			}
		}
	}
}

#course-dates {
	text-align: center;
	margin-bottom: 40px;
	h3 {
		color: #d8d8d8;
		margin-bottom: 10px;
	}
	h2 {
		margin-bottom: 40px;
	}
	b {
		display: block;
		font-size: 24px;
		margin-bottom: 10px;
	}
	p {
		display: inline-block;
		max-width: 540px;
		font-size: 18px;
		margin-bottom: 30px;
	}
	button {
		display: inline-block;
		margin-bottom: 40px;
	}
	.date-box {
		padding-bottom: 20px;
		border: 1px solid #d8d8d8;
		margin-bottom: 15px;
		cursor: pointer;
		.month {
			background-color: #d8d8d8;
			font-size: 16px;
			font-weight: 700;
			color: white;
			margin-bottom: 20px;
		}
		&.selected .month {
			background-color: $red;
		}
		.date {
			font-size: 30px;
			font-weight: 900;
			span {
				font-size: 14px;
			}
		}
		.day {
			font-size: 16px;
		}
		&.course-full {
			background-color: #ebebeb;
			color: #8f8b8b;
			margin-bottom: 5px;
			.month {
				color: #8f8b8b;
				background-color: #ebebeb;
				border-bottom: 1px solid white;
			}
			+ span {
				display: block;
				font-weight: $bold;
				color: $red;
				font-size: 16px;
				margin-bottom: 15px;
			}
		}
	}
	#signup-form {
		text-align: center;
		margin-bottom: 40px;
		.button {
			display: inline-block;
		}
	}
}

#waitingList {
	.modal-content {
		padding: 40px;
		text-align: center;
		h2 {
			font-size: 45px;
			line-height: 1em;
			margin: 0 0 10px;
			font-weight: 900;
			color: $gray;
		}
		p {
			font-size: 18px;
			font-weight: $bold;
		}
		input, button {
			width: 100%;
		}
		form {
			margin-top: 20px;
		}
		button {
			margin-top: 20px;
			margin-bottom: 10px;
		}
	}
}