body.home {
	background-image: url('../img/fondo-home.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	@media(max-width: $max-sm){
		background-image: url('../img/fondo-home_mobile.png');
	}
	header {
		@media(max-width: $max-menu){
		border-bottom: 1px solid $grayL;
			#logo svg path, #logo svg polygon {
				fill: $red !important;
			}
			&.open {
				#logo svg path,#logo  svg polygon {
					fill: white !important;
				}
				
			}
			#shopping-cart path {
				stroke: $red;
			}
		}
	}
}
.home-block {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 160px;
	@media(max-width: $max-xs){
		margin-bottom: 0;
		flex-direction: column-reverse;
	}
	&:nth-child(even){
		flex-direction: row-reverse;
		@media(max-width: $max-xs){
			flex-direction: column-reverse;
		}
	}
	.thumb {
		height: 75vH;
		width: 42%;
		@media(max-width: $max-xs){
			width: 100%;
			height: 325px;
			margin-top: 15px;
			margin-bottom: 60px;
		}
	}
	.content {
		width: 58%;
		padding: 0 13% 0 8%;
		line-height: 30px;
		@media(max-width: $max-sm){
			padding: 0 5% 0 8%;
		}
		@media(max-width: $max-xs){
			width: 100%;
			padding: 0 25px;
		}
		div {
			font-size: 24px;
			padding-right: 20%;
			padding-top: 10px;
			padding-bottom: 30px;
			@media(max-width: $max-sm){
				padding-right: 20px;
			}
			@media(max-width: $max-xs){
				font-size: 20px;
				padding-bottom: 15px;
			}
		}
		a.a-chevron {
			@media(max-width: $max-xs){
				font-size: 18px;
			}
		}
	}

}
.home-text-block {
	margin-top: 225px;
	text-align: center;
	@media(max-width: $max-xs){
		margin-top: 60px;
	}
	h2 {
		margin-bottom: 30px;
		@media(max-width: $max-xs){
			margin-bottom: 20px;
		}
	}
	&#home-intro {
		margin-bottom: 170px;
		@media(max-width: $max-xs){
			margin-bottom: 100px;
		}
	}
}
#home-header {
	text-align: center;
	height: calc(100vH - 75px);
	.row {
		margin: 0;
		height: 100%;
		align-items: center;
		@media(max-width: $max-sm){
			align-items: initial;
			padding-top: 100px;
		}
		@media(max-width: $max-xs){
			padding-top: 30px;
		}
	}
	h1 {
		font-size: 90px;
		line-height: 1em;
		@media(max-width: $max-xs){
			font-size: 60px;
		}
	}
}
#home-courses, #home-news {
	margin-top: 160px;
	@media(max-width: $max-xs){
		margin-top: 60px;
	}
	.thumb {
 		padding-bottom: 62%;
 		/*@media(max-width: $max-xs){
			height: 185px;
		}*/
	}
	h2 {
		text-align: center;
	}
}
#home-courses, #courses {
	h2 {
		margin-bottom: 80px;
		@media(max-width: $max-sm){
			margin-bottom: 50px;
		}
		@media(max-width: $max-xs){
			margin-bottom: 30px;
		}
	}
	.col-md-6 {
		@media(max-width: $max-sm){
			margin-bottom: 25px;
		}
	}
	.course {
		height: 100%;
		.content {
			border-left: 2px solid $grayL;
			border-right: 2px solid $grayL;
			border-bottom: 2px solid $grayL;
			padding: 30px;
			display: flex;
			flex-direction: column;
			background-color: white;
			@media(max-width: $max-sm){
				padding: 15px;
			}
			@media(min-width: $min-md){
				height: calc(100% - 270px);
			}
			.date {
				text-transform: uppercase;
				color: $gray;
				opacity: 0.5;
				display: block;
				font-weight: 700;
				margin-bottom: 15px;
				@media(max-width: $max-sm){
					margin-bottom: 5px;
				}
			}
			.h3-wrap {
				display: block;
				flex-grow: 1;
			}
			h3 {
				color: $gray;
				padding-right: 20%;
				margin-bottom: 40px;
				@media(max-width: $max-xs){
					padding-right: 0;
				}
			}
			.course-footer {
				a {
					float: right;
				}
			}
		}
	}
}
#home-news {
	margin-top: 175px;
	color: white;
	overflow: hidden;
	@media(max-width: $max-xs){
		margin-top: 60px;
	}
	h2 {
		margin-bottom: 80px;
		@media(max-width: $max-sm){
			margin-bottom: 50px;
		}
		@media(max-width: $max-xs){
			margin-bottom: 30px;
		}
	}
	.new-wrap {
		width: 33%;
		height: 100%;
		padding: 0 10px;
		@media(max-width: $max-xs){
			padding: 0 4px;
		}
		.new {
			background-color: $beige;
			height: 100%;
			.content {
				padding: 30px;
				height: calc(100% - 270px);
				display: flex;
				flex-direction: column;
				@media(max-width: $max-sm){
					padding: 20px;
				}
				@media(max-width: $max-xs){
					padding: 20px 15px;
				}
				.date {
					display: block;
					text-transform: uppercase;
					font-weight: 700;
					margin-bottom: 15px;
					@media(max-width: $max-xs){
						margin-bottom: 10px;
					}
				}
				h3 {
					color: white;
					margin-bottom: 20px;
					@media(max-width: $max-xs){
						margin-bottom: 15px;
					}
				}
				.description {
					flex-grow: 1;
				}
				.readmore {
					display: block;
					font-weight: 700;
					margin-top: 20px;
					@media(max-width: $max-xs){
						margin-top: 15px;
					}
				}
			}
		}
	}
}
/*.new-wrap:not(.slick-active) {
	cursor: url(../img/left.svg), auto;
}
.new-wrap.slick-active + .new-wrap:not(.slick-active){
	cursor: url(../img/right.svg), auto;
}*/
#home-news .new-wrap:not(.slick-active) a {
	pointer-events: none; 
}


#home-testimonials, #courses-testimonials, #course-testimonials {
	margin-top: 225px;
	overflow: hidden;
	@media(max-width: $max-xs){
		margin-top: 60px;
	}
	h2 {
		text-align: center;
		margin-bottom: 75px;
		@media(max-width: $max-sm){
			margin-bottom: 50px;
		}
		@media(max-width: $max-xs){
			margin-bottom: 30px;
		}
	}
	.student-data {
		.thumb {
			float: left;
			width: 70px;
			height: 70px;
			border-radius: 50%;
		}
		h3 {
			font-size: 24px;
			line-height: 1em;
			color: $gray;
			font-weight: 700;
			margin-bottom: 5px;
			@media(max-width: $max-sm){
				font-size: 20px;
			}
		}
	}
	.testimonial-wrap {
		width: 33%;
		height: 100%;
		padding: 0 10px;
		@media(max-width: $max-xs){
			padding: 0 4px;
		}
		.testimonial {
			border: 2px solid $grayL;
			padding: 30px;
			height: 100%;
			background-color: white;
			@media(max-width: $max-sm){
				padding: 20px;
			}
			.thumb {
				margin-right: 15px;
			}
			.student-data {
				overflow: auto;
			}
			.content {
				font-size: 24px;
				margin-top: 3.5em;
				line-height: 30px;
				padding-bottom: 2em;
				@media(max-width: $max-sm){
					font-size: 16px;
					line-height: 24px;
					margin-top: 2em;
				}
				&:before, &:after {
					color: $red;
					font-weight: $black;
				}
				&:before {
					content: "“";
				}
				&:after {
					content: "“";
				}
			}
			span {
				/*@media(max-width: $max-md){
					display: block;
					max-height: 52px;
					overflow: hidden;
					position: relative;
					&:after {
						content: '...';
						position: absolute;
						bottom: 0;
						right: 0;
					}
				}*/
			}
		}
	}
}

#contact-us {
	margin-top: 80px;
}

#home-sponsors {
	margin-top: 160px;
	text-align: center;
	h2 {
		margin-bottom: 70px;
	}
	.row {
		justify-content: center;
	}
	.sponsor {
		margin-bottom: 70px;
		img {
			height: 120px;
		}
	}
}