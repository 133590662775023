::selection {
	
}

body {
	font-family: $mark;
	color: $gray;
	font-size: 18px;
	line-height: 26px;
	@media(max-width: $max-sm){
		font-size: 16px;
		line-height: 24px;
	}
}
h1,h2,h3,h4 {
	margin: 0;
	padding: 0;
}
h1 {
	font-size: 80px;
	line-height: 80px;
	color: $red;
	font-weight: 900;
	@media(max-width: $max-sm){
		font-size: 60px;
		line-height: 60px;
	}
	@media(max-width: $max-xs){
		font-size: 45px;
		line-height: 45px;
	}
}
h2, .h2 {
	font-size: 70px;
	line-height: 75px;	
	color: $red;
	font-weight: 900;
	&.small {
		font-weight: 900;
		font-size: 45px;
		line-height: 45px;
	}
	@media(max-width: $max-sm){
		font-size: 50px;
		line-height: 50px;
	}
	@media(max-width: $max-xs){
		font-size: 45px;
		line-height: 45px;
	}
}
h3, .h3 {
	font-size: 30px;
	line-height: 36px;
	color: $red;
	font-weight: 900;
	@media(max-width: $max-sm){
		font-size: 20px;
		line-height: 26px;
	}
}
h4 {
	font-size: 30px;
	line-height: 36px;
	font-weight: 700;
	&.light {
		font-weight: 400;
	}
	@media(max-width: $max-sm){
		font-size: 20px;
		line-height: 26px;
	}
}
p {
	margin: 0;
	a {
		color: $red;
		text-decoration: underline;
	}
}
b, strong {
	font-weight: 700;
}
a, a:hover, a:focus {
	color: inherit;
	text-decoration: none;
}
.caption {
	font-size: 16px;
	line-height: 24px;
}

.green { color: $green; }
.greenD { color: $greenD; }
.red { color: $red; }
.beige { color: $beige; }
.beigeD { color: $beigeD; }

a.a-chevron {
	font-weight: 700;
	i {
		font-size: 10px;
		vertical-align: 1px;
		margin-left: 5px;
	}
}

.courses {
	.current-menu-item { color: $green; }
}
.news {
	.current-menu-item { color: $beige; }
}
.news-single {
	h1,h2,h3,h4, .current-menu-item {color: $beigeD; }
}
.aurea {
	h1,h2,h3,h4, .current-menu-item {color: $yellow; }
}
.faqs {
	.current-menu-item {color: $orange; }
}