.checkout {
	background-color: $grayL;
}

#cart {
	background-color: white;
	padding: 30px;
	h1, h2 {
		color: $gray;
		font-size: 30px;
		line-height: 40px;
		font-weight: $black;
	}
	h1 {
		margin-bottom: 25px;
		padding-bottom: 20px;
		border-bottom: 1px solid $grayL;
	}
	.cart_product {
		> div {
			margin-bottom: 25px;
			padding-bottom: 25px;
			border-bottom: 1px solid $grayL;
		}
		h2 {
			margin-bottom: 5px;
		}
		.thumb {
			padding-bottom: 70%;
		}
		.cat {
			font-weight: $bold;
			color: $greenD;
			margin-top: 20px;
		}
		.text {
			display: flex;
			flex-direction: column;
			.content {
				flex-grow: 1;
				p {
					display: inline;
				}
				a {
					font-weight: bold;
					font-size: 14px;
					color: $red;
				}
			}
		}
	}
	.price {
		font-size: 24px;
		font-weight: $bold;
		text-align: right;
	}
	#cart_content {
		
	}
}

.price {
	padding: 0;
	.discount_wrap {
		margin-bottom: 10px;
	}
	.discount {
		padding: 8px 13px;
		font-size: 16px;
		color: white;
		background-color: $red;
		font-weight: $bold;
		margin-right: 5px;
	}
	.discount_price {
		position: relative;
		color: #d8d8d8;
		margin-bottom: 10px;
		&:after {
			content: " ";
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			height: 3px;
			margin-top: -2px;
			background-color: $red;
		}
	}
	.delete_cart {
		display: inline-block;
		button {
			-webkit-appearance: none;
			border: 0;
			padding: 0;
			background-color: transparent;
			margin-left: 10px;
			cursor: pointer;
		}
	}
}

#cart-total {
	margin-bottom: 30px;
	padding-bottom: 50px;
	border-bottom: 1px solid $grayL;
	.small-text {
		color: #d8d8d8;
		font-size: 14px;
		line-height: 16px;
		b {
			display: block;
			margin-bottom: 20px;
		}
	}
}

#cart-payment {
	text-align: right;
	.button {
		display: inline-block;
		padding-left: 20px;
		padding-right: 20px;
		color: white;
		&:hover {
			color: $red;
		}
	}
	.already-customer {
		margin-top: 20px;
	}
	form {
		display: inline-block;
	}
}

.already-customer {
	color: $red;
	font-weight: $bold;
	display: block;
}

#gift {
	display: none;
}